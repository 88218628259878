import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from '../img/logo-visio.png'

function Header() {
  return (
    <header className="sticky-top w-100">
      <Navbar variant="dark" expand="lg" className="bg-black">
        <Container fluid='lg'>
          <NavLink to={'/'} className="me-5"><Image className="logo" width={322} height={40} src={logo} fluid/></NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink to={'/'} className="navLink text-white-50 text-decoration-none me-5">Accueil</NavLink>
              <NavLink to={'forfaits'} className="navLink text-white-50 text-decoration-none me-5">Forfaits</NavLink>
              <NavLink to={'hotesses'} className="navLink text-white-50 text-decoration-none me-5">Hôtesses en Visio</NavLink>
              <NavLink to={'visio'} className="navLink text-white-50 text-decoration-none">Visio</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header;