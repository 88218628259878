import hostessesVisio from '../constants/HostessesVisioArray';
import headerHostessesVisio from '../img/header-hotesses-visio.png';
import { Container, Image, Card, Row, Col } from "react-bootstrap";
import { useState } from 'react';

function Hostesses() {
  const [profilHostesse, setProfilHostesse] = useState({
    name: "",
    photo: "",
    description: "",
    display: false
  });

  function scroll(section) {
    window.scrollTo({
      top: document.getElementById(section).getBoundingClientRect().y + window.scrollY
    })
  }

  function displayProfil(name, photo, description) {
    document.getElementById('section-profil').style.display = "block";
    setProfilHostesse({
      photo: photo,
      name: name,
      description: description,
      display: true
    })
    setTimeout(() => { scroll("section-profil"); }, 1);
  }

  function displayChat(){
    document.getElementById("iframe-chat").style.display = "block";
    setTimeout(() => { scroll("iframe-chat"); }, 1);
  }

  return (
    <>
      <section className="header pt-3 mb-4">
        <Container fluid='lg'>
          <Row>
            <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
              <div className='title-header-page'>
                <h1 className="mb-4 text-center text-lg-start fw-bold display-5">Découvrez Nos Hôtesses de Téléphone Rose en Visio</h1>
                <h2 className="fs-4 text-center text-lg-start fw-normal">Choisissez parmis l'une de nos hôtesses et échangez en direct</h2>
              </div>
            </Col>
            <Col xs={12} lg={6} className='p-0'>
              <div className='image-header-page text-center'>
                <Image src={headerHostessesVisio} fluid />
              </div>
            </Col>
          </Row>
        </Container >
      </section >
      <Container as={"section"} fluid="lg" className="container-hostesses mt-3 mt-lg-5 text-center">
        <h2 className="title-red-hostesses fs-3 py-3 py-md-4 mb-5 fw-bold">Bienvenue sur notre page dédiée aux hôtesses de téléphone rose disponibles 24/24</h2>
        <p>Nos hôtesses expérimentées sont prêtes à répondre à vos désirs les plus intimes, vous offrant des moments sur mesure et une écoute attentive. Sélectionnez l’hôtesse idéale pour satisfaire toutes vos envies, jour et nuit. La confidentialité et le respect sont garantis pour chaque appel, vous permettant de vous évader en toute sérénité.</p>
        <Row as={"ul"} xs={2} sm={3} md={4} xl={5} className="mt-5 p-0">
          {hostessesVisio.map(hostesse => (
            <Col key={hostesse.id} as={"li"} className="mb-3">
              <Card onClick={() => displayProfil(hostesse.name, hostesse.photo, hostesse.description)} className="h-100 rounded-1 border-0 bg-black text-white" text="black">
                <Card.Img className="rounded-top-1 photo-hostesses" variant="top" src={`/img/photos_visio/photos_large/${hostesse.photo}.webp`} alt={hostesse.alt} fluid />
                <Card.Body className='card-body rounded-bottom-1'>
                  <Card.Title className="m-0 fw-bold card-title-custom">{hostesse.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Container id="section-profil" as={"section"} style={{ display: "none" }} className="mt-3" fluid="lg">
        <Row>
          <Col xl={5}>
            <Image src={`/img/photos_visio/photos_large/${profilHostesse.photo}.webp`} fluid />
          </Col>
          <Col>
            <h3>{profilHostesse.name}</h3>
            <>
              {profilHostesse.description}
            </>
            <button onClick={() => displayChat()}>Lancer le chat-visio</button>
          </Col>
        </Row>
      </Container>
      <Container id="iframe-chat" style={{ display: "none" }} className="mt-3" fluid="lg">
        <iframe src="https://html5-chat.com/chat/50573/66d58b670ca26" allow="camera; fullscreen" frameborder="0" width="100%" height="825"></iframe>
      </Container>
    </>
  )
}

export default Hostesses;