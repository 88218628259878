import { Container, Row, Col, Image } from "react-bootstrap";
import headerPackagesVisio from '../img/header-forfaits-visio.png';
// import packageVisio1 from '../img/forfait-visio-1.png';
// import packageVisio2 from '../img/forfait-visio-2.png';
// import packageVisio3 from '../img/forfait-visio-3.png';
import packageVisio1 from '../img/carte-forfait-1.png';
import packageVisio2 from '../img/carte-forfait-2.png';
import packageVisio3 from '../img/carte-forfait-3.png';
import packageVisio4 from '../img/carte-forfait-4.png';

function Packages() {
  return (
    <>
      <section className="header pt-3 mb-4">
        <Container fluid='lg'>
          <Row>
            <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
              <div className='title-header-page'>
                <h1 className="mb-4 text-center text-lg-start fw-bold display-5">Forfaits Téléphone Rose en Visio : Expériences Intimes en Direct </h1>
                <h2 className="fs-4 text-center text-lg-start fw-normal">Découvrez nos différentes gammes de forfaits pour des appels en visio</h2>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className='image-header-page text-center'>
                <Image src={headerPackagesVisio} fluid />
              </div>
            </Col>
          </Row>
        </Container >
      </section >
      <Container fluid="lg" as={"section"}>
        <Row>
          <Col xs={12} sm={6} md={4} lg={3} className="text-center"><a target="_blank" href="https://app.dialotel.io/login/167-sexy-love-visio/0-forfait/7-forfait-prive/719-forfait-visio-decouverte"><Image className="card-package" src={packageVisio1} rounded fluid /></a></Col>
          <Col xs={12} sm={6} md={4} lg={3} className="text-center"><a target="_blank" href="https://app.dialotel.io/login/167-sexy-love-visio/0-forfait/7-forfait-prive/720-forfait-visio-standard"><Image className="card-package" src={packageVisio2} rounded fluid /></a></Col>
          <Col xs={12} sm={6} md={4} lg={3} className="text-center"><a target="_blank" href="https://app.dialotel.io/login/167-sexy-love-visio/0-forfait/7-forfait-prive/724-forfait-visio-premium"><Image className="card-package" src={packageVisio3} rounded fluid /></a></Col>
          <Col xs={12} sm={6} md={4} lg={3} className="text-center"><a target="_blank" href="https://app.dialotel.io/login/167-sexy-love-visio/0-forfait/7-forfait-prive/1242-forfait-visio-vip"><Image className="card-package" src={packageVisio4} rounded fluid /></a></Col>
        </Row>
        {/* <iframe frameborder="0" src="https://app.dialotel.io/forfaitprive/iframe_forfaits/167/1" width="100%" height="800"></iframe> */}
        {/* <iframe frameborder="0" src="https://moduleweb.dialotel.io/modulweb/token/649034c1a00e8" width="100%" height="800"></iframe> */}
      </Container>
    </>
  )
}

export default Packages;