import { Container, Row, Col } from "react-bootstrap";

function Visio() {

  function displayChatVisio() {
    document.getElementById("iframe-chat-visio").style.display = "block"
  }
  return (
    <Container>
      <h1>Packages</h1>
      <Row>
        <Col>
          <button onClick={displayChatVisio} className="button-chat-visio p-3 rounded-5 d-block text-center">Rejoindre le chat visio</button>
        </Col>
        <Col md={1}>
          <span className="d-block text-center p-3 fs-4">ou</span>
        </Col>
        <Col>
          <a href="https://join.skype.com/invite/NvTpq6UWYJAq" target="_blank" className="button-chat-skype p-3 rounded-5 d-block text-center">Rejoindre depuis Skype</a>
        </Col>
      </Row>
      <iframe id="iframe-chat-visio" className="iframe-chat-visio" src="https://html5-chat.com/chat/50573/66d58b670ca26" width={"100%"} height={800} frameborder="0"></iframe>
    </Container>
  )
}

export default Visio;