const hostessesVisioArray = [
  {
    id: 1,
    name: "Laura",
    photo: "laura",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Une séductrice innée, elle sait captiver votre attention dès le premier regard. Son sourire envoûtant et son regard mystérieux vous feront chavirer instantanément.",
    metaTitle: "Hôtesse de Téléphone Rose | Conversations Sensuelles & Discrètes avec Cammille au 090340840",
    metaDescription: "Découvrez une expérience sensuelle et personnalisée avec notre hôtesse de téléphone rose. Conversations envoûtantes, plaisir garanti, confidentialité totale.",
    description:
      <>
        <p>Salut, moi c’est Laura, une jeune femme de 26 ans au charme irrésistible. Mes atouts physiques ne passent pas inaperçus, comme tu as pu le constater sur ma photo.</p>
        <p>Je suis à la fois douce, sensuelle et pleine de vie. Si tu as envie de partager un moment de plaisir intense, n’hésite pas à m’appeler. Que ce soit au téléphone ou en visio, je saurai te faire vibrer.</p>
        <p>Que ce soit au téléphone ou en visio, je saurai te faire vibrer. J’ai hâte de te rencontrer pour des instants brûlants. À très vite ! Bisous.</p>
      </>
  },
  {
    id: 2,
    name: "Linda",
    photo: "linda",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Lola est une maîtresse des fantasmes et des plaisirs visuels. Avec une voix envoûtante et un regard captivant, elle sait comment éveiller vos désirs les plus profonds.",
    metaTitle: "Hôtesse de Téléphone Rose - Conversation Sensuelle et Confidentielle avec Sarah au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Évasion, fantasmes et plaisir dans un cadre confidentiel et respectueux.",
    description:
      <>
        <p>Linda semble ordinaire, comme tant d'autres, mignonne, intelligente, drôle. Mais derrière son apparence sage, se cachent des pensées audacieuses.</p>
        <p>Pervers et sans honte, elle adore se masturber au téléphone, se livrant à ses fantasmes les plus secrets. Infatigable et passionnée, Linda s'abandonne à ses désirs sans limites, prête à réaliser vos fantasmes les plus inavouables.</p>
        <p>Confidentiel, sans restrictions, c'est l'occasion parfaite de laisser libre cours à vos envies. N'hésitez plus, appelez-la et explorez ensemble le plaisir sans tabous.</p>
      </>
  },
  {
    id: 3,
    name: "Tanya",
    photo: "tanya",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Lola est une maîtresse des fantasmes et des plaisirs visuels. Avec une voix envoûtante et un regard captivant, elle sait comment éveiller vos désirs les plus profonds.",
    metaTitle: "Hôtesse de Téléphone Rose - Conversation Sensuelle et Confidentielle avec Sarah au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Évasion, fantasmes et plaisir dans un cadre confidentiel et respectueux.",
    description:
      <>
        <p>Coucou les garçons ! Moi, c’est Tanyah, une petite nana aux formes généreuses et au sourire ravageur. Si tu cherches un moment d’évasion et de plaisir, tu es au bon endroit.</p>
        <p> Que ce soit pour une conversation enflammée au téléphone ou une visio pleine de charme, je suis là pour rendre chaque instant inoubliable.</p>
        <p>N'attends plus, viens vite me rejoindre pour un duo pimenté qui promet d'être aussi doux que torride. J’ai hâte de te faire succomber…</p>
      </>
  },
  {
    id: 4,
    name: "Aurelia",
    photo: "aurelia",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Lola est une maîtresse des fantasmes et des plaisirs visuels. Avec une voix envoûtante et un regard captivant, elle sait comment éveiller vos désirs les plus profonds.",
    metaTitle: "Hôtesse de Téléphone Rose - Conversation Sensuelle et Confidentielle avec Sarah au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Évasion, fantasmes et plaisir dans un cadre confidentiel et respectueux.",
    description:
      <>
        <p>Je suis une fille joyeuse et pleine de vie, toujours entourée de mes amis. Mais mon secret, c'est mon amour pour le sexe sous toutes ses formes.</p>
        <p>J'aime varier entre la passion intense et la tendresse. Le sexe au téléphone est ma manière préférée de me sublimer.</p>
        <p>Si tu partages cette passion pour les plaisirs érotiques, appelle-moi ! Ensemble, on ne s'ennuiera jamais.</p>
      </>
  },
  {
    id: 5,
    name: "Vick",
    photo: "vick",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Élise est une hôtesse de téléphone rose en visio, connue pour sa voix douce et sensuelle, ainsi que son sourire enchanteur.",
    metaTitle: "Éveillez vos Sens avec Charlotte : Hôtesse de Téléphone Rose | 090340840",
    metaDescription: "Découvrez Charlotte, votre hôtesse de téléphone rose. Conversations envoûtantes, moments sur mesure et discrétion totale. Évadez-vous et vivez des instants mémorables.",
    description:
      <>
        <p>Salut les garçons  c'est Vick. Je suis à la recherche d'un homme pour partager nos fantasmes et nos envies.</p>
        <p>Que tu sois timide ou expérimenté, tu es le bienvenu ! le plus important  c'est de partager ensemble du plaisir ...</p>
        <p>Si tu veux en  savoir plus et  me découvrir je t'invite à me contacter.. A bientôt !</p>
      </>
  },
  {
    id: 6,
    name: "Karine",
    photo: "karine",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Lola est une maîtresse des fantasmes et des plaisirs visuels. Avec une voix envoûtante et un regard captivant, elle sait comment éveiller vos désirs les plus profonds.",
    metaTitle: "Hôtesse de Téléphone Rose - Conversation Sensuelle et Confidentielle avec Sarah au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Évasion, fantasmes et plaisir dans un cadre confidentiel et respectueux.",
    description:
      <>
        <p>Si tu es attiré par une femme sensuelle et sans tabous, n’hésite pas à me contacter pour vivre un moment intense ou simplement échanger autour de discussions érotiques.</p>
        <p>Je suis ouverte à toutes les envies, sans aucune limite. Avec moi, tu pourras aborder tout ce que tu désires, et ensemble, nous pourrons explorer tes fantasmes.</p>
        <p>Je suis une coquine pleine de désirs, et j'apprécie les hommes qui savent tenir une conversation tout en prenant plaisir à se caresser mutuellement et à s'exciter ensemble.</p>
      </>
  },
  {
    id: 7,
    name: "Nathalie",
    photo: "nathalie",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Maîtresse dans l’art de la séduction, elle vous surprendra par son imagination débordante et ses propositions audacieuses.",
    metaTitle: "Moments Intimes avec Pauline : Téléphone Rose au 090340840",
    metaDescription: "Découvrez Pauline, votre hôtesse de téléphone rose. Conversations passionnées, écoute attentive, et moments sur mesure pour une expérience intime et inoubliable.",
    description:
      <>
        <p>Coucou je m'appelle Nathalie,  je suis une petite brune de 24 ans, avec de belles formes et une jolie poitrine.</p>
        <p>Viens me voir si tu as envie d'une coquine très chaude. Je voudrais te faire plaisir en te dévoilant mes petits secrets intimes.</p>
        <p>J'apprécie aussi d'avoir un petit soumis à mes pieds pour ceux  qui sont tentés.. Alors viens me voir devant ma webcam !</p>
      </>
  },
  {
    id: 8,
    name: "Fanny",
    photo: "fanny",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle excelle dans l’art de jouer avec les fantasmes, offrant des moments de plaisir inédits et personnalisés.",
    metaTitle: "Hôtesse de Téléphone Rose : Expérience Sensuelle avec Marine au 090340840",
    metaDescription: "Découvrez Marine, votre hôtesse de téléphone rose, pour des conversations envoûtantes et personnalisées. Moments chaleureux, confidentiels et sur mesure vous attendent.",
    description:
      <>
        <p>Salut, c’est FANNY, une brune aux yeux marron et aux courbes envoûtantes qui n’attend que toi pour partager des moments intenses et excitants. Avec mes 1m60 de charme et un corps sculpté pour le plaisir (90C de tour de poitrine et des fesses bien bombées), je suis prête à donner vie à tous tes fantasmes.</p>
        <p>Que tu aies envie de te confier ou de plonger dans des scénarios torrides, je suis là pour t'écouter et te guider. Ma chatte bien rasée n'est qu'un avant-goût de ce que tu pourras découvrir. Grâce à ma webcam, tu auras la chance de me voir en direct, d'admirer chaque détail et de vivre des moments personnalisés qui te feront perdre la tête.</p>
        <p>Je suis ouverte à toutes les envies, que ce soit pour des confidences intimes ou pour explorer des scénarios où tout est possible. Laisse-moi être celle qui exaucera tes désirs les plus fous. À très vite !</p>
      </>
  },
  {
    id: 9,
    name: "Lydie",
    photo: "lydie",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle excelle dans l’art de jouer avec les fantasmes, offrant des moments de plaisir inédits et personnalisés.",
    metaTitle: "Hôtesse de Téléphone Rose : Expérience Sensuelle avec Marine au 090340840",
    metaDescription: "Découvrez Marine, votre hôtesse de téléphone rose, pour des conversations envoûtantes et personnalisées. Moments chaleureux, confidentiels et sur mesure vous attendent.",
    description:
      <>
        <p>Coucou, c’est LYDIE, une jolie brune aux yeux noirs profonds, avec une silhouette élégante et des courbes à couper le souffle. Je mesure 1m70, avec 60 kg de pur plaisir, un 90C qui ne demande qu’à être découvert, et des fesses bien rebondies qui sauront captiver ton regard.</p>
        <p>Je suis une femme très agréable et sans complexe, prête à t’offrir une expérience unique en visio et au téléphone. Ma chatte bien rasée est un secret que je n’attends qu’à partager avec toi, dans une ambiance chaude et sensuelle.</p>
        <p>Je suis là pour t’accueillir dans un duo où chaque moment sera dédié à ton plaisir. Que tu aies envie d'une conversation intime ou d'un échange plus osé, je saurai te mettre à l’aise et t’offrir un moment inoubliable.</p>
        <p>Viens me rejoindre pour partager ce moment intense et découvrir tout ce que je peux t'offrir. Bisous, et à très vite !</p>
      </>
  },
  {
    id: 10,
    name: "Angie",
    photo: "angie",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Lola est une maîtresse des fantasmes et des plaisirs visuels. Avec une voix envoûtante et un regard captivant, elle sait comment éveiller vos désirs les plus profonds.",
    metaTitle: "Hôtesse de Téléphone Rose - Conversation Sensuelle et Confidentielle avec Sarah au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Évasion, fantasmes et plaisir dans un cadre confidentiel et respectueux.",
    description:
      <>
        <p>Si tu veux vivre un moment inoubliable, je suis Angie, une blonde légèrement rousse, pleine d’énergie et toujours à l'écoute. Mon but ? Te procurer un plaisir total à travers un échange intensément sensuel.</p>
        <p>Je t’invite à découvrir le téléphone rose en visio, où notre rencontre ira bien au-delà d’une simple conversation. Avec moi, c’est une expérience passionnante et enivrante qui t’attend. Prépare-toi pour un moment brûlant et plein de désir, loin d'une discussion banale.</p>
        <p>Si tu préfères, je peux aussi être plus tendre et faire monter le désir progressivement jusqu’à l’extase. Alors, tu es prêt pour une aventure unique en télrose visio ?</p>
      </>
  },
  {
    id: 11,
    name: "Alysee",
    photo: "alysee",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle excelle dans l’art de jouer avec les fantasmes, offrant des moments de plaisir inédits et personnalisés.",
    metaTitle: "Hôtesse de Téléphone Rose : Expérience Sensuelle avec Marine au 090340840",
    metaDescription: "Découvrez Marine, votre hôtesse de téléphone rose, pour des conversations envoûtantes et personnalisées. Moments chaleureux, confidentiels et sur mesure vous attendent.",
    description:
      <>
        <p>Bonjour, c’est ALYSEE, une coquine brune aux yeux noirs envoûtants, prête à t’emmener dans un monde de plaisir et de complicité. Avec mes 24 ans, je mesure 1m68 pour 56 kg de pure sensualité, et un 85D de tour de poitrine qui saura te faire rêver.</p>
        <p>Je suis très ouverte et sans tabous, toujours prête à partager avec toi tes petites folies et à explorer ensemble tes désirs les plus secrets. Ma chatte bien épilée est un trésor que je te dévoilerai dans une ambiance aussi chaude que captivante.</p>
        <p>Envie de plus ? Tu pourras me découvrir en visio, pour une expérience encore plus intense et personnalisée. Je serai là pour te faire vivre des moments inoubliables, où chaque instant est dédié à ton plaisir.</p>
        <p>Alors, ne perds pas de temps, viens vite me rejoindre et partageons ensemble des moments de folie. À très bientôt !</p>
      </>
  },
]

export default hostessesVisioArray;