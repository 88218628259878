import headerHomeVisio from '../img/header-home-visio.png';
import { Container, Row, Col, Image } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

function Home() {
  return (
    <>
      <section className="header pt-3 mb-4">
        <Container fluid='lg'>
          <Row>
            <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
              <div className='title-header-page'>
                <h1 className="mb-4 text-center text-lg-start fw-bold display-5">Service de Téléphone Rose en Visio  Rapide et Discret </h1>
                <h2 className="fs-4 text-center text-lg-start fw-normal">Découvrez des échanges vidéo privés et excitants avec nos hôtesses en toute discrétion.</h2>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className='image-header-page text-center'>
                <Image src={headerHomeVisio} fluid />
              </div>
            </Col>
          </Row>
        </Container >
      </section >
      <Container fluid='lg' as={"section"}>
        <Row>
          <Col xs={12} md={6} className='mb-4 mb-md-0'>
            <article className="d-flex flex-column h-100 p-3 text-center article-home rounded">
              <h3 className='mb-4 fw-bold'>Découvrez nos Forfaits Exclusifs pour des Appels Visio</h3>
              <p className='mb-4'>Plongez dans une expérience unique avec nos forfaits spécialement conçus pour des appels visio avec l'une de nos hôtesses. Que vous cherchiez à pimenter vos conversations ou à vivre des moments intenses et captivants, nous avons le forfait qu'il vous faut !</p>
              <NavLink to={'forfaits'} className="mt-auto mx-auto article-link p-2 rounded-5">Les forfaits</NavLink>
            </article>
          </Col>
          <Col xs={12} md={6}>
            <article className="d-flex flex-column h-100 p-3 text-center article-home rounded">
              <h3 className='mb-4 fw-bold'>Bienvenue dans notre univers de séduction et de plaisir !</h3>
              <p className='mb-4'>Vous êtes sur le point de découvrir un espace où vos fantasmes prennent vie et où chaque appel est une expérience unique. Nos hôtesses sont là pour vous offrir des moments intimes et inoubliables à travers des appels visio personnalisés.</p>
              <NavLink to={'hotesses'} className="mt-auto mx-auto article-link p-2 rounded-5">Nos hôtesses en visio</NavLink>
            </article>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home;